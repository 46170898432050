var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("统计报表")]),
              _c("el-breadcrumb-item", [_vm._v("出入库报表")]),
              _c("el-breadcrumb-item", [_vm._v("采购记录")])
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-form",
          {
            attrs: {
              inline: true,
              model: _vm.searchForm,
              size: "small",
              "label-suffix": ":"
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              },
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.searchSubmit.apply(null, arguments)
              }
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "采购订单号" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入采购订单号", clearable: "" },
                  model: {
                    value: _vm.searchForm.orderNumber,
                    callback: function($$v) {
                      _vm.$set(_vm.searchForm, "orderNumber", $$v)
                    },
                    expression: "searchForm.orderNumber"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "供应商" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择", clearable: "" },
                    model: {
                      value: _vm.searchForm.supplierId,
                      callback: function($$v) {
                        _vm.$set(_vm.searchForm, "supplierId", $$v)
                      },
                      expression: "searchForm.supplierId"
                    }
                  },
                  _vm._l(_vm.supplierList, function(item) {
                    return _c("el-option", {
                      key: item.ID,
                      attrs: { label: item.name, value: item.ID }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "采购员" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择", clearable: "" },
                    model: {
                      value: _vm.searchForm.buyerUid,
                      callback: function($$v) {
                        _vm.$set(_vm.searchForm, "buyerUid", $$v)
                      },
                      expression: "searchForm.buyerUid"
                    }
                  },
                  _vm._l(_vm.buyerList, function(item) {
                    return _c("el-option", {
                      key: item.uid,
                      attrs: { label: item.name, value: item.uid }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "采购日期" } },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "daterange",
                    "unlink-panels": true,
                    "range-separator": "至",
                    "start-placeholder": "开始日期",
                    "end-placeholder": "结束日期"
                  },
                  model: {
                    value: _vm.searchForm.dateRange,
                    callback: function($$v) {
                      _vm.$set(_vm.searchForm, "dateRange", $$v)
                    },
                    expression: "searchForm.dateRange"
                  }
                })
              ],
              1
            ),
            _vm.moreOptions.indexOf("productNumber") > -1
              ? _c(
                  "el-form-item",
                  { attrs: { label: "产品编号" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入产品编号", clearable: "" },
                      model: {
                        value: _vm.searchForm.productNumber,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "productNumber", $$v)
                        },
                        expression: "searchForm.productNumber"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.moreOptions.indexOf("productName") > -1
              ? _c(
                  "el-form-item",
                  { attrs: { label: "产品名称" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入产品名称", clearable: "" },
                      model: {
                        value: _vm.searchForm.productName,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "productName", $$v)
                        },
                        expression: "searchForm.productName"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.moreOptions.indexOf("specificationModel") > -1
              ? _c(
                  "el-form-item",
                  { attrs: { label: "规格型号" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "规格型号", clearable: "" },
                      model: {
                        value: _vm.searchForm.specificationModel,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "specificationModel", $$v)
                        },
                        expression: "searchForm.specificationModel"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.moreOptions.indexOf("batchNumber") > -1
              ? _c(
                  "el-form-item",
                  { attrs: { label: "批号" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入批号", clearable: "" },
                      model: {
                        value: _vm.searchForm.batchNumber,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "batchNumber", $$v)
                        },
                        expression: "searchForm.batchNumber"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.moreOptions.indexOf("trackingCode") > -1
              ? _c(
                  "el-form-item",
                  { attrs: { label: "序列号" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入序列号", clearable: "" },
                      model: {
                        value: _vm.searchForm.trackingCode,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "trackingCode", $$v)
                        },
                        expression: "searchForm.trackingCode"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.moreOptions.indexOf("invoiceNumber") > -1
              ? _c(
                  "el-form-item",
                  { attrs: { label: "发票号" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入发票号", clearable: "" },
                      model: {
                        value: _vm.searchForm.invoiceNumber,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "invoiceNumber", $$v)
                        },
                        expression: "searchForm.invoiceNumber"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "el-form-item",
              [
                _c(
                  "el-popover",
                  {
                    ref: "optionsPopover",
                    attrs: { placement: "bottom", trigger: "click" }
                  },
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        staticClass: "dropdown_checkbox",
                        on: { change: _vm.moreOptionsChange },
                        model: {
                          value: _vm.moreOptions,
                          callback: function($$v) {
                            _vm.moreOptions = $$v
                          },
                          expression: "moreOptions"
                        }
                      },
                      [
                        _c(
                          "el-checkbox",
                          { attrs: { label: "productNumber" } },
                          [_vm._v("产品编号")]
                        ),
                        _c("el-checkbox", { attrs: { label: "productName" } }, [
                          _vm._v("产品名称")
                        ]),
                        _c(
                          "el-checkbox",
                          { attrs: { label: "specificationModel" } },
                          [_vm._v("规格型号")]
                        ),
                        _c("el-checkbox", { attrs: { label: "batchNumber" } }, [
                          _vm._v("批号")
                        ]),
                        _c(
                          "el-checkbox",
                          { attrs: { label: "trackingCode" } },
                          [_vm._v("序列号")]
                        ),
                        _c(
                          "el-checkbox",
                          { attrs: { label: "invoiceNumber" } },
                          [_vm._v("发票号")]
                        )
                      ],
                      1
                    ),
                    _c("el-button", {
                      attrs: { slot: "reference", icon: "el-icon-plus" },
                      slot: "reference"
                    })
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "10px" },
                    attrs: { type: "primary" },
                    on: { click: _vm.searchSubmit }
                  },
                  [_vm._v("查询")]
                ),
                _c("el-button", [_vm._v("导出")])
              ],
              1
            )
          ],
          1
        ),
        _c("drag-table", {
          attrs: {
            "route-name": _vm.$route.name + "-detail",
            pagination: _vm.searchForm,
            "table-head": _vm.tableHeadDetail,
            data: _vm.tableData
          }
        })
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              align: "center",
              total: _vm.searchForm.total,
              "current-page": _vm.searchForm.page,
              "page-size": _vm.searchForm.pageSize,
              "page-sizes": [5, 10, 20, 50, 100]
            },
            on: {
              "size-change": _vm.pageSizeChange,
              "current-change": _vm.pageChange
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }