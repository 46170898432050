var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "d2-container",
        { staticClass: "page" },
        [
          _c(
            "template",
            { slot: "header" },
            [
              _c(
                "el-breadcrumb",
                { attrs: { separator: "/" } },
                [
                  _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                    _vm._v("首页")
                  ]),
                  _c("el-breadcrumb-item", [_vm._v("库房管理")]),
                  _c("el-breadcrumb-item", [_vm._v("入库管理")]),
                  _c("el-breadcrumb-item", [_vm._v("库存盘点（价格）")])
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        icon: "el-icon-plus",
                        type: "primary"
                      },
                      on: { click: _vm.orderCreate }
                    },
                    [_vm._v("新增盘存单")]
                  )
                ],
                1
              )
            ],
            1
          ),
          [
            _c(
              "div",
              { staticClass: "index-container" },
              [
                _c(
                  "el-card",
                  { staticClass: "form-card", attrs: { shadow: "never" } },
                  [
                    _c(
                      "el-form",
                      {
                        attrs: {
                          inline: true,
                          model: _vm.searchForm,
                          size: "small",
                          "label-suffix": ":"
                        },
                        nativeOn: {
                          submit: function($event) {
                            $event.preventDefault()
                          },
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.formSubmit.apply(null, arguments)
                          }
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "仓库" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择",
                                  clearable: "",
                                  filterable: ""
                                },
                                model: {
                                  value: _vm.searchForm.warehouseId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.searchForm, "warehouseId", $$v)
                                  },
                                  expression: "searchForm.warehouseId"
                                }
                              },
                              _vm._l(_vm.warehouseList, function(item) {
                                return _c("el-option", {
                                  key: item.ID,
                                  attrs: { label: item.name, value: item.ID }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "盘存日期" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "daterange",
                                "unlink-panels": true,
                                "range-separator": "至",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                                format: "yyyy-MM-dd",
                                "value-format": "yyyy-MM-dd"
                              },
                              model: {
                                value: _vm.searchForm.dateRange,
                                callback: function($$v) {
                                  _vm.$set(_vm.searchForm, "dateRange", $$v)
                                },
                                expression: "searchForm.dateRange"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "状态" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "150px" },
                                attrs: { placeholder: "请选择", clearable: "" },
                                model: {
                                  value: _vm.searchForm.documentStatus,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.searchForm,
                                      "documentStatus",
                                      $$v
                                    )
                                  },
                                  expression: "searchForm.documentStatus"
                                }
                              },
                              _vm._l(_vm.documentStatusList, function(item) {
                                return _c("el-option", {
                                  key: item.ID,
                                  attrs: { label: item.name, value: item.ID }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm.searchFormExpand
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "盘存单号" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入盘存单号",
                                    clearable: ""
                                  },
                                  model: {
                                    value: _vm.searchForm.inventoryDocumentNo,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "inventoryDocumentNo",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.inventoryDocumentNo"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-search"
                                },
                                on: { click: _vm.formSubmit }
                              },
                              [_vm._v("查询")]
                            ),
                            _c(
                              "el-link",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: { type: "primary", underline: false },
                                on: {
                                  click: function($event) {
                                    _vm.searchFormExpand = !_vm.searchFormExpand
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.searchFormExpand ? "合并" : "展开")
                                ),
                                _c("i", {
                                  class:
                                    "el-icon-arrow-" +
                                    (_vm.searchFormExpand ? "up" : "down") +
                                    " el-icon--right"
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("YTable", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.orderLoading,
                      expression: "orderLoading"
                    }
                  ],
                  ref: "dragTableRef",
                  attrs: {
                    hasIndex: true,
                    pagination: _vm.searchForm,
                    "route-name": _vm.$route.name,
                    columns: _vm.columns,
                    data: _vm.orderList
                  },
                  on: {
                    "row-click": _vm.orderRowClick,
                    "row-dblclick": _vm.orderRowDbClick
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "operations",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c(
                            "el-link",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.orderEdit(row)
                                }
                              }
                            },
                            [_vm._v("查看详情")]
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c(
                  "div",
                  { staticStyle: { margin: "10px 0" } },
                  [
                    _c("el-pagination", {
                      attrs: {
                        background: "",
                        layout: "total, sizes, prev, pager, next, jumper",
                        align: "center",
                        total: _vm.searchForm.total,
                        "page-sizes": [5, 10, 20, 50, 100],
                        "current-page": _vm.searchForm.page,
                        "page-size": _vm.searchForm.pageSize
                      },
                      on: {
                        "current-change": _vm.searchPageChange,
                        "size-change": _vm.searchSizeChange
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.detailLoading,
                        expression: "detailLoading"
                      }
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.detailList,
                      size: "small",
                      "highlight-current-row": "",
                      stripeDELETE: "",
                      border: "",
                      "header-cell-style": {
                        background: "#f5f7fa",
                        borderColor: "#ebeef5",
                        color: "#333"
                      }
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "ID",
                        label: "序号",
                        width: "100",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "productNumber",
                        width: "100",
                        label: "产品编号",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "main.productName",
                        width: "150",
                        label: "物资名称",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "specs.genericName",
                        width: "150",
                        label: "通用名称",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "trackingCode",
                        width: "150",
                        label: "序列号",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "specs.specificationModel",
                        width: "100",
                        label: "规格型号",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "batchNumber",
                        width: "100",
                        label: "生产批号",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "inventory.stock_quantity",
                        width: "100",
                        label: "库存数量",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "inventory.issueLocking",
                        width: "100",
                        label: "锁定数量",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "inventory.normalQuantity",
                        width: "100",
                        label: "可用数量",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "physicalInventory",
                        width: "100",
                        label: "盘点数量",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "profitLossQuantity",
                        width: "100",
                        label: "盈亏数量",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "unitPrice",
                        label: "单价",
                        width: "100",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "profitLossAmount",
                        label: "盈亏金额",
                        width: "100",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "specs.unit",
                        label: "单位",
                        width: "100",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "",
                        label: "包装规格",
                        align: "center",
                        width: "100"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(scope.row.specs.packNum) +
                                  _vm._s(scope.row.specs.unit) +
                                  " / " +
                                  _vm._s(scope.row.specs.packUnit) +
                                  " "
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "main.productPlace",
                        label: "产地",
                        align: "center",
                        width: "100",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "main.brandName",
                        label: "品牌",
                        align: "center",
                        width: "100",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "manufacturerDate",
                        label: "生产日期",
                        align: "center",
                        width: "100",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "expirationDate",
                        label: "有效期",
                        align: "center",
                        width: "100",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "trackingCode",
                        label: "序列号",
                        align: "center",
                        width: "100",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "inventory.sterilization_batch_number",
                        label: "灭菌批号",
                        width: "100",
                        align: "center",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "inventory.sterilization_date",
                        label: "灭菌效期",
                        align: "center",
                        width: "100",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "main.manufacturer",
                        label: "生产厂家",
                        align: "center",
                        width: "200",
                        "show-overflow-tooltip": ""
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "reason",
                        label: "原因",
                        align: "center",
                        width: "200",
                        "show-overflow-tooltip": ""
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticStyle: { margin: "10px 0" } },
                  [
                    _c("el-pagination", {
                      attrs: {
                        background: "",
                        layout: "total, sizes, prev, pager, next, jumper",
                        align: "center",
                        total: _vm.detailForm.total,
                        "page-sizes": [5, 10, 20, 50, 100],
                        "current-page": _vm.detailForm.page,
                        "page-size": _vm.detailForm.pageSize
                      },
                      on: {
                        "current-change": _vm.detailPageChange,
                        "size-change": _vm.pageSizeChange
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }