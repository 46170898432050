var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("库房管理")]),
              _c("el-breadcrumb-item", [_vm._v("出库管理")]),
              _c("el-breadcrumb-item", [_vm._v("有台出库")]),
              _c("el-breadcrumb-item", [
                _vm._v(
                  "出库单详情（" +
                    _vm._s(_vm.action === "edit" ? "修改" : "") +
                    "）"
                )
              ])
            ],
            1
          ),
          _c(
            "div",
            [
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-refresh-right"
                },
                on: { click: _vm.refresh }
              })
            ],
            1
          )
        ],
        1
      ),
      [
        _vm.orderRow.ID
          ? _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.detailLoading,
                    expression: "detailLoading"
                  }
                ],
                ref: "form",
                staticClass: "form-table",
                attrs: {
                  model: _vm.form,
                  rules: _vm.formRules,
                  size: "mini",
                  "label-width": "100px",
                  "show-message": false
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-tabs",
                  [
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "订单信息" } },
                      [
                        _c(
                          "el-row",
                          {
                            staticStyle: { "flex-wrap": "wrap" },
                            attrs: { type: "flex" }
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { sm: 12, lg: 8, xl: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "订单号码" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { margin: "0 10px 0 15px" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.form.deliveryOrderNo) +
                                            " "
                                        ),
                                        _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "bottom",
                                              width: "auto",
                                              trigger: "click"
                                            }
                                          },
                                          [
                                            _c("img", {
                                              staticStyle: {
                                                height: "30px",
                                                width: "200px"
                                              },
                                              attrs: { id: "barcode" }
                                            }),
                                            _c(
                                              "el-link",
                                              {
                                                attrs: {
                                                  slot: "reference",
                                                  underline: false
                                                },
                                                on: { click: _vm.showBarCode },
                                                slot: "reference"
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fa fa-barcode",
                                                  attrs: {
                                                    "aria-hidden": "true"
                                                  }
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { sm: 12, lg: 8, xl: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "库房状态" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { margin: "0 10px 0 15px" }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.orderRow.allStatusName)
                                          )
                                        ]),
                                        _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "right-start",
                                              title: "订单流程",
                                              trigger: "click",
                                              width: "400"
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "max-height": "400px",
                                                  overflow: "auto",
                                                  "padding-right": "20px"
                                                }
                                              },
                                              [
                                                _c(
                                                  "el-timeline",
                                                  [
                                                    _c(
                                                      "el-timeline-item",
                                                      {
                                                        attrs: {
                                                          timestamp: "出库",
                                                          placement: "top"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "el-card",
                                                          {
                                                            attrs: {
                                                              shadow: "hover"
                                                            }
                                                          },
                                                          [
                                                            _vm.erpProcessList
                                                              .listStock
                                                              .length > 0
                                                              ? _vm._l(
                                                                  _vm
                                                                    .erpProcessList
                                                                    .listStock,
                                                                  function(
                                                                    item2,
                                                                    index2
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key: index2,
                                                                        staticStyle: {
                                                                          display:
                                                                            "flex",
                                                                          "flex-direction":
                                                                            "row",
                                                                          "justify-content":
                                                                            "space-between",
                                                                          "font-size":
                                                                            "12px",
                                                                          "line-height":
                                                                            "20px"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm._v(
                                                                              " ["
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticStyle: {
                                                                                  color:
                                                                                    "#409EFF"
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item2.operator
                                                                                  )
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " ] " +
                                                                                _vm._s(
                                                                                  item2.desc
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticStyle: {
                                                                              color:
                                                                                "#ccc",
                                                                              width:
                                                                                "140px",
                                                                              "text-align":
                                                                                "right"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item2.createTime
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                )
                                                              : _c("div", [
                                                                  _vm._v("暂无")
                                                                ])
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-timeline-item",
                                                      {
                                                        attrs: {
                                                          timestamp: "销售",
                                                          placement: "top"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "el-card",
                                                          {
                                                            attrs: {
                                                              shadow: "hover"
                                                            }
                                                          },
                                                          [
                                                            _vm.erpProcessList
                                                              .listSales
                                                              .length > 0
                                                              ? _vm._l(
                                                                  _vm
                                                                    .erpProcessList
                                                                    .listSales,
                                                                  function(
                                                                    item2,
                                                                    index2
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key: index2,
                                                                        staticStyle: {
                                                                          display:
                                                                            "flex",
                                                                          "flex-direction":
                                                                            "row",
                                                                          "justify-content":
                                                                            "space-between",
                                                                          "font-size":
                                                                            "12px",
                                                                          "line-height":
                                                                            "20px"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm._v(
                                                                              " ["
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticStyle: {
                                                                                  color:
                                                                                    "#409EFF"
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item2.operator
                                                                                  )
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " ] " +
                                                                                _vm._s(
                                                                                  item2.desc
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticStyle: {
                                                                              color:
                                                                                "#ccc",
                                                                              width:
                                                                                "140px",
                                                                              "text-align":
                                                                                "right"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item2.createTime
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                )
                                                              : _c("div", [
                                                                  _vm._v("暂无")
                                                                ])
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-timeline-item",
                                                      {
                                                        attrs: {
                                                          timestamp: "财务",
                                                          placement: "top"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "el-card",
                                                          {
                                                            attrs: {
                                                              shadow: "hover"
                                                            }
                                                          },
                                                          [
                                                            _vm.erpProcessList
                                                              .listFinance
                                                              .length > 0
                                                              ? _vm._l(
                                                                  _vm
                                                                    .erpProcessList
                                                                    .listFinance,
                                                                  function(
                                                                    item2,
                                                                    index2
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        key: index2,
                                                                        staticStyle: {
                                                                          display:
                                                                            "flex",
                                                                          "flex-direction":
                                                                            "row",
                                                                          "justify-content":
                                                                            "space-between",
                                                                          "font-size":
                                                                            "12px",
                                                                          "line-height":
                                                                            "20px"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          [
                                                                            _vm._v(
                                                                              " ["
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticStyle: {
                                                                                  color:
                                                                                    "#409EFF"
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item2.operator
                                                                                  )
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " ] " +
                                                                                _vm._s(
                                                                                  item2.desc
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticStyle: {
                                                                              color:
                                                                                "#ccc",
                                                                              width:
                                                                                "140px",
                                                                              "text-align":
                                                                                "right"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item2.createTime
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  }
                                                                )
                                                              : _c("div", [
                                                                  _vm._v("暂无")
                                                                ])
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-link",
                                              {
                                                attrs: {
                                                  slot: "reference",
                                                  underline: false
                                                },
                                                slot: "reference"
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fa fa-list-ul",
                                                  staticStyle: {
                                                    display: "flex",
                                                    "flex-direction": "row",
                                                    "align-items": "center",
                                                    "justify-content": "center",
                                                    "font-size": "14px",
                                                    "margin-left": "5px"
                                                  },
                                                  attrs: {
                                                    "aria-hidden": "true"
                                                  }
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { sm: 12, lg: 8, xl: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "配送状态" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { margin: "0 10px 0 15px" }
                                      },
                                      [
                                        _vm.form.hospitalStock === 0
                                          ? _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.deliveryStatusFormatter(
                                                    _vm.form.deliveryStatus
                                                  )
                                                )
                                              }
                                            })
                                          : _c(
                                              "el-tag",
                                              { attrs: { size: "small" } },
                                              [_vm._v("医院备货")]
                                            ),
                                        _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "right-start",
                                              title: "配送流程",
                                              trigger: "click",
                                              width: "400"
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "max-height": "400px",
                                                  overflow: "auto",
                                                  "padding-right": "20px"
                                                }
                                              },
                                              [
                                                _vm.deliveryProcessList.length >
                                                0
                                                  ? _c(
                                                      "el-timeline",
                                                      _vm._l(
                                                        _vm.deliveryProcessList,
                                                        function(item, index) {
                                                          return _c(
                                                            "el-timeline-item",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                timestamp:
                                                                  item.stepName,
                                                                placement: "top"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "el-card",
                                                                {
                                                                  attrs: {
                                                                    shadow:
                                                                      "hover"
                                                                  }
                                                                },
                                                                [
                                                                  item.info
                                                                    .length > 0
                                                                    ? _vm._l(
                                                                        item.info,
                                                                        function(
                                                                          item2,
                                                                          index2
                                                                        ) {
                                                                          return _c(
                                                                            "div",
                                                                            {
                                                                              key: index2,
                                                                              staticStyle: {
                                                                                display:
                                                                                  "flex",
                                                                                "flex-direction":
                                                                                  "row",
                                                                                "justify-content":
                                                                                  "space-between",
                                                                                "font-size":
                                                                                  "12px",
                                                                                "line-height":
                                                                                  "20px"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  style:
                                                                                    item2.em ===
                                                                                    1
                                                                                      ? "color:#67C23A"
                                                                                      : ""
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " [" +
                                                                                      _vm._s(
                                                                                        item2.job
                                                                                      ) +
                                                                                      "-"
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticStyle: {
                                                                                        color:
                                                                                          "#409EFF"
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          item2.uname
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " ] " +
                                                                                      _vm._s(
                                                                                        item2.do
                                                                                      ) +
                                                                                      " "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticStyle: {
                                                                                    color:
                                                                                      "#ccc",
                                                                                    width:
                                                                                      "140px",
                                                                                    "text-align":
                                                                                      "right"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      item2.createTime
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "暂无"
                                                                          )
                                                                        ]
                                                                      )
                                                                ],
                                                                2
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  : _c("span", [_vm._v("无")])
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-link",
                                              {
                                                attrs: {
                                                  slot: "reference",
                                                  underline: false
                                                },
                                                slot: "reference"
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fa fa-list-ul",
                                                  staticStyle: {
                                                    display: "flex",
                                                    "flex-direction": "row",
                                                    "align-items": "center",
                                                    "justify-content": "center",
                                                    "font-size": "14px",
                                                    "margin-left": "5px"
                                                  },
                                                  attrs: {
                                                    "aria-hidden": "true"
                                                  }
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { sm: 12, lg: 8, xl: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "仓库" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { margin: "0 10px 0 15px" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.form.warehouseName) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { sm: 12, lg: 8, xl: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "医院" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { margin: "0 10px 0 15px" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.form.hospitalName) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { sm: 12, lg: 8, xl: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "归属公司",
                                      prop: "subsidiaryId"
                                    }
                                  },
                                  [
                                    _vm.form.status === 1 ||
                                    _vm.form.status === 3
                                      ? _c(
                                          "el-select",
                                          {
                                            attrs: {
                                              clearable: "",
                                              filterable: "",
                                              placeholder: "请选择"
                                            },
                                            on: { change: _vm.formStorage },
                                            model: {
                                              value: _vm.form.subsidiaryId,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "subsidiaryId",
                                                  $$v
                                                )
                                              },
                                              expression: "form.subsidiaryId"
                                            }
                                          },
                                          _vm._l(_vm.subsidiaryList, function(
                                            item
                                          ) {
                                            return _c("el-option", {
                                              key: "subsidiaryId" + item.ID,
                                              attrs: {
                                                label: item.companyName,
                                                value: item.ID
                                              }
                                            })
                                          }),
                                          1
                                        )
                                      : _c(
                                          "span",
                                          {
                                            staticClass: "item-text",
                                            staticStyle: { color: "#666" }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.orderRow.subsidiary
                                                  .companyName
                                              )
                                            )
                                          ]
                                        )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { sm: 12, lg: 8, xl: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "备货类型" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { margin: "0 10px 0 15px" }
                                      },
                                      [
                                        _vm.form.status === 1
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-radio-group",
                                                  {
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.hospitalStockChange()
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.form.hospitalStock,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.form,
                                                          "hospitalStock",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "form.hospitalStock"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          effect: "dark",
                                                          content:
                                                            "选择了库房送货，会有出入库配送环节操作",
                                                          placement: "top"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "el-radio",
                                                          {
                                                            attrs: { label: 0 }
                                                          },
                                                          [_vm._v("库房配送")]
                                                        )
                                                      ],
                                                      1
                                                    ),
                                                    _vm.remindConfig
                                                      .hospitalStockStatus === 1
                                                      ? _c(
                                                          "el-tooltip",
                                                          {
                                                            attrs: {
                                                              effect: "dark",
                                                              content:
                                                                "选择了医院备货，不会有出入库配送流程",
                                                              placement: "top"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "el-radio",
                                                              {
                                                                attrs: {
                                                                  label: 1
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "医院备货"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _c("div", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.orderRow
                                                      .hospitalStock === 1
                                                      ? "医院备货"
                                                      : "库房配送"
                                                  ) +
                                                  " "
                                              )
                                            ])
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { sm: 12, lg: 8, xl: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "工具",
                                      prop: "tools",
                                      rules: _vm.noTools
                                        ? [{ required: false }]
                                        : _vm.formRules.target
                                    }
                                  },
                                  [
                                    _c("ToolWeight", {
                                      staticStyle: { overflow: "hidden" },
                                      attrs: {
                                        noTools: _vm.noTools,
                                        productWeight: _vm.productWeight,
                                        toolsWeight: _vm.form.toolsWeight,
                                        dataStatus: _vm.form.status === 1
                                      },
                                      on: {
                                        "update:noTools": function($event) {
                                          _vm.noTools = $event
                                        },
                                        "update:no-tools": function($event) {
                                          _vm.noTools = $event
                                        },
                                        change: function($event) {
                                          return _vm.toolsStockChange()
                                        },
                                        "update:toolsWeight": function($event) {
                                          return _vm.$set(
                                            _vm.form,
                                            "toolsWeight",
                                            $event
                                          )
                                        },
                                        "update:tools-weight": function(
                                          $event
                                        ) {
                                          return _vm.$set(
                                            _vm.form,
                                            "toolsWeight",
                                            $event
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.form.tools,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "tools", $$v)
                                        },
                                        expression: "form.tools"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { sm: 12, lg: 8, xl: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "总重量合计" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { margin: "0 10px 0 15px" }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.form.toolsWeight
                                                ? _vm.form.toolsWeight
                                                : 0
                                            )
                                          )
                                        ]),
                                        _c("span", [_vm._v("kg")])
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "auto-height",
                                attrs: { sm: 12, lg: 24, xl: 18 }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "备注" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { margin: "0 10px 0 15px" }
                                      },
                                      [
                                        _c("div", [
                                          _c("div", [
                                            _vm._v(
                                              _vm._s(_vm.form.returnRemarks)
                                            )
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "flex-direction": "row"
                                              }
                                            },
                                            _vm._l(
                                              _vm.form.returnRemarksPic,
                                              function(item, index) {
                                                return _c(
                                                  "div",
                                                  { key: index },
                                                  [
                                                    _c("el-image", {
                                                      staticStyle: {
                                                        width: "45px",
                                                        height: "45px",
                                                        "margin-right": "10px"
                                                      },
                                                      attrs: {
                                                        src: item.url,
                                                        fit: "cover",
                                                        "preview-src-list": _vm.toList(
                                                          _vm.form
                                                            .returnRemarksPic
                                                        )
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        ]),
                                        _vm.form.status !== 6
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticStyle: {
                                                      "margin-right": "10px"
                                                    },
                                                    attrs: {
                                                      type: "primary",
                                                      size: "mini"
                                                    },
                                                    on: {
                                                      click: _vm.editRemark
                                                    }
                                                  },
                                                  [_vm._v("填写")]
                                                ),
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      type: "primary",
                                                      size: "mini"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.phoneUpload()
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("手机上传附件")]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.audioAttachList.length > 0
                                          ? _c(
                                              "el-popover",
                                              {
                                                attrs: {
                                                  placement: "left",
                                                  width: "200",
                                                  trigger: "click"
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    attrs: { align: "center" }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        attrs: {
                                                          align: "center"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "语音留言请点击播放"
                                                        )
                                                      ]
                                                    ),
                                                    _vm._l(
                                                      _vm.audioAttachList,
                                                      function(item, index) {
                                                        return _c("m-audio", {
                                                          key:
                                                            "audio-attch-" +
                                                            index,
                                                          staticStyle: {
                                                            margin: "20px 0"
                                                          },
                                                          attrs: {
                                                            block: true,
                                                            src: item.url
                                                          }
                                                        })
                                                      }
                                                    )
                                                  ],
                                                  2
                                                ),
                                                _c(
                                                  "el-link",
                                                  {
                                                    attrs: {
                                                      slot: "reference"
                                                    },
                                                    slot: "reference"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                          "align-items":
                                                            "center",
                                                          "flex-direction":
                                                            "row"
                                                        }
                                                      },
                                                      [
                                                        _c("d2-icon-svg", {
                                                          staticStyle: {
                                                            width: "20px",
                                                            height: "20px"
                                                          },
                                                          attrs: {
                                                            name: "audio"
                                                          }
                                                        }),
                                                        _c("div", [
                                                          _vm._v("语音留言")
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "手术信息" } },
                      [
                        _c(
                          "el-row",
                          {
                            staticStyle: { "flex-wrap": "wrap" },
                            attrs: { type: "flex" }
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { sm: 12, lg: 8, xl: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "手术状态" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { margin: "0 10px 0 15px" }
                                      },
                                      [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.surgeryStatusFormatter(
                                                _vm.followStatus
                                              )
                                            )
                                          }
                                        }),
                                        _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "right-start",
                                              title: "手术流程",
                                              trigger: "click",
                                              width: "400"
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "max-height": "400px",
                                                  overflow: "auto",
                                                  "padding-right": "20px"
                                                }
                                              },
                                              [
                                                _vm.surgeryProcessList.length >
                                                0
                                                  ? _c(
                                                      "el-timeline",
                                                      _vm._l(
                                                        _vm.surgeryProcessList,
                                                        function(item, index) {
                                                          return _c(
                                                            "el-timeline-item",
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                timestamp:
                                                                  item.stepName,
                                                                placement: "top"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "el-card",
                                                                {
                                                                  attrs: {
                                                                    shadow:
                                                                      "hover"
                                                                  }
                                                                },
                                                                [
                                                                  item.info
                                                                    .length > 0
                                                                    ? _vm._l(
                                                                        item.info,
                                                                        function(
                                                                          item2,
                                                                          index2
                                                                        ) {
                                                                          return _c(
                                                                            "div",
                                                                            {
                                                                              key: index2,
                                                                              staticStyle: {
                                                                                display:
                                                                                  "flex",
                                                                                "flex-direction":
                                                                                  "row",
                                                                                "justify-content":
                                                                                  "space-between",
                                                                                "font-size":
                                                                                  "12px",
                                                                                "line-height":
                                                                                  "20px"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  style:
                                                                                    item2.em ===
                                                                                    1
                                                                                      ? "color:#67C23A"
                                                                                      : ""
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " [" +
                                                                                      _vm._s(
                                                                                        item2.job
                                                                                      ) +
                                                                                      "-"
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticStyle: {
                                                                                        color:
                                                                                          "#409EFF"
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          item2.uname
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " ] " +
                                                                                      _vm._s(
                                                                                        item2.do
                                                                                      ) +
                                                                                      " "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticStyle: {
                                                                                    color:
                                                                                      "#ccc",
                                                                                    width:
                                                                                      "140px",
                                                                                    "text-align":
                                                                                      "right"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      item2.createTime
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        }
                                                                      )
                                                                    : _c(
                                                                        "div",
                                                                        [
                                                                          _vm._v(
                                                                            "暂无"
                                                                          )
                                                                        ]
                                                                      )
                                                                ],
                                                                2
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  : _c("span", [_vm._v("无")])
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-link",
                                              {
                                                attrs: {
                                                  slot: "reference",
                                                  underline: false
                                                },
                                                slot: "reference"
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fa fa-list-ul",
                                                  staticStyle: {
                                                    display: "flex",
                                                    "flex-direction": "row",
                                                    "align-items": "center",
                                                    "justify-content": "center",
                                                    "font-size": "14px",
                                                    "margin-left": "5px"
                                                  },
                                                  attrs: {
                                                    "aria-hidden": "true"
                                                  }
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { sm: 12, lg: 8, xl: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "手术医生" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { margin: "0 10px 0 15px" }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.form.surgeon) + " "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { sm: 12, lg: 8, xl: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "病人姓名" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { margin: "0 10px 0 15px" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.form.patientName) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { sm: 12, lg: 8, xl: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "手术时间" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { margin: "0 10px 0 15px" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.form.operationTime) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { sm: 12, lg: 8, xl: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "手术类型" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { margin: "0 10px 0 15px" }
                                      },
                                      [
                                        _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "bottom",
                                              trigger: "click"
                                            }
                                          },
                                          [
                                            _vm.form.nameOperationType
                                              ? _c(
                                                  "ul",
                                                  {
                                                    staticClass: "surgery-list"
                                                  },
                                                  _vm._l(
                                                    _vm.form.nameOperationType,
                                                    function(item, index) {
                                                      return _c(
                                                        "li",
                                                        {
                                                          key:
                                                            "nameOperationType-" +
                                                            index
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.surgerySubCategoryName
                                                                  ? "【" +
                                                                      item.surgerySubCategoryName +
                                                                      "" +
                                                                      (item.position
                                                                        ? "-" +
                                                                          item.position
                                                                        : "") +
                                                                      "】"
                                                                  : ""
                                                              ) +
                                                                _vm._s(
                                                                  item.surgeryName ||
                                                                    item.surgery_name
                                                                )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  width: "100%",
                                                  cursor: "pointer"
                                                },
                                                attrs: { slot: "reference" },
                                                slot: "reference"
                                              },
                                              [
                                                _vm.form.nameOperationType
                                                  .length > 0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                          "justify-content":
                                                            "space-between",
                                                          "align-items":
                                                            "center"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              height: "40px",
                                                              flex: "1",
                                                              overflow:
                                                                "hidden",
                                                              "text-overflow":
                                                                "ellipsis",
                                                              "white-space":
                                                                "nowrap",
                                                              width: "0"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.form
                                                                  .nameOperationType[0]
                                                                  .surgerySubCategoryName
                                                                  ? "【" +
                                                                      _vm.form
                                                                        .nameOperationType[0]
                                                                        .surgerySubCategoryName +
                                                                      "" +
                                                                      (_vm.form
                                                                        .nameOperationType[0]
                                                                        .position
                                                                        ? "-" +
                                                                          _vm
                                                                            .form
                                                                            .nameOperationType[0]
                                                                            .position
                                                                        : "") +
                                                                      "】"
                                                                  : ""
                                                              ) +
                                                                _vm._s(
                                                                  _vm.form
                                                                    .nameOperationType[0]
                                                                    .surgeryName ||
                                                                    _vm.form
                                                                      .nameOperationType[0]
                                                                      .surgery_name
                                                                )
                                                            )
                                                          ]
                                                        ),
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-arrow-down",
                                                          staticStyle: {
                                                            width: "40px",
                                                            "text-align":
                                                              "center",
                                                            padding: "0 10px",
                                                            color: "#C0C4CC"
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { sm: 12, lg: 8, xl: 6 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "手术备注" } },
                                  [_vm._v(" " + _vm._s(_vm.form.remarks) + " ")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { sm: 24, lg: 24, xl: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "手术描述" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { margin: "0 10px 0 15px" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.youtaiOrderInfo
                                                .surgeryDescribe
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              {
                                staticClass: "auto-height",
                                attrs: { sm: 24, lg: 24, xl: 24 }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "X光照片" } },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { display: "flex" } },
                                      _vm._l(
                                        _vm.youtaiOrderInfo.xRayPhotos,
                                        function(item, index) {
                                          return _c("el-image", {
                                            key: "xRayPhotos-" + index,
                                            staticStyle: {
                                              width: "30px",
                                              height: "30px",
                                              margin: "10px"
                                            },
                                            attrs: {
                                              src: item,
                                              fit: "cover",
                                              "preview-src-list":
                                                _vm.youtaiOrderInfo.xRayPhotos
                                            }
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "其他信息" } },
                      [
                        _c(
                          "el-row",
                          {
                            staticStyle: { "flex-wrap": "wrap" },
                            attrs: { type: "flex" }
                          },
                          [
                            _c(
                              "el-col",
                              { attrs: { sm: 12, lg: 12, xl: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "销售人员" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { margin: "0 10px 0 15px" }
                                      },
                                      [_vm._v(_vm._s(_vm.form.nameSalesperson))]
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { sm: 12, lg: 12, xl: 12 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "跟台员" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { margin: "0 10px 0 15px" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.orderRow.nameAttendant
                                                ? _vm.orderRow.nameAttendant
                                                : "待指派"
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "row",
              "justify-content": "space-between",
              margin: "10px 0",
              "align-items": "center"
            }
          },
          [
            _c(
              "div",
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      icon: "el-icon-plus",
                      disabled: _vm.enableButtons() === false
                    },
                    on: { click: _vm.selectStock }
                  },
                  [_vm._v("增加产品")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      icon: "el-icon-plus",
                      disabled: _vm.enableButtons() === false
                    },
                    on: { click: _vm.selectConsum }
                  },
                  [_vm._v("增加耗材包")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      icon: "el-icon-plus",
                      disabled: _vm.enableButtons() === false
                    },
                    on: { click: _vm.selectConsumSet }
                  },
                  [_vm._v("从耗材组套导入")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "danger",
                      size: "mini",
                      disabled: _vm.enableButtons() === false
                    },
                    on: { click: _vm.scanInput }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-barcode",
                      attrs: { "aria-hidden": "true" }
                    }),
                    _vm._v(" 扫码录入 ")
                  ]
                ),
                _vm.orderRow.status >= 3
                  ? _c(
                      "el-checkbox",
                      {
                        staticStyle: { "margin-left": "10px" },
                        on: { change: _vm.scanFilterChange },
                        model: {
                          value: _vm.scanFilter,
                          callback: function($$v) {
                            _vm.scanFilter = $$v
                          },
                          expression: "scanFilter"
                        }
                      },
                      [_vm._v("扫码在列表中搜索")]
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm.youtaiOrderInfo.saleReDetailProducts
              ? _c(
                  "div",
                  [
                    _vm.youtaiOrderInfo.saleReDetailProducts.length > 0
                      ? _c(
                          "el-link",
                          {
                            staticStyle: { "font-size": "16px" },
                            attrs: { type: "danger" },
                            on: { click: _vm.viewRecommandProduct }
                          },
                          [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "18px" } },
                              [_vm._v("!")]
                            ),
                            _vm._v("销售推荐产品 ")
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticStyle: {
                  "font-size": "14px",
                  display: "flex",
                  "flex-direction": "row",
                  "align-items": "center"
                }
              },
              [
                _c("div", [
                  _vm._v(" 出库数量合计：（"),
                  _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.countQuantity()))
                  ]),
                  _vm._v("） ")
                ]),
                _c("div", [
                  _vm._v(" 使用数量合计：（"),
                  _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.countUsedQuantity()))
                  ]),
                  _vm._v("） ")
                ]),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "mini",
                      disabled: !_vm.form.id
                    },
                    on: { click: _vm.orderPrint }
                  },
                  [
                    _c("i", { staticClass: "fa fa-print" }),
                    _vm._v(" 打印出库单")
                  ]
                )
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showScanInputBox,
                expression: "showScanInputBox"
              }
            ]
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  margin: "10px 0",
                  display: "flex",
                  "align-items": "center"
                }
              },
              [
                _c(
                  "div",
                  { staticStyle: { width: "450px" } },
                  [
                    _c("el-input", {
                      ref: "scanInputBoxRef",
                      staticClass: "bar-input",
                      attrs: { placeholder: "请扫描产品条码", clearable: "" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.barCodeSearch.apply(null, arguments)
                        }
                      },
                      model: {
                        value: _vm.scanBarCode,
                        callback: function($$v) {
                          _vm.scanBarCode = $$v
                        },
                        expression: "scanBarCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      color: "#f00",
                      flex: "1",
                      "margin-left": "20px"
                    }
                  },
                  [_vm._v(_vm._s(_vm.tipMessage))]
                )
              ]
            )
          ]
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.detailLoading,
              expression: "detailLoading"
            }
          ],
          ref: "detailListTable",
          attrs: {
            hasIndex: true,
            hasPagination: true,
            slotIndex: true,
            hasExpand: true,
            pagination: _vm.detailForm,
            "route-name": _vm.$route.name + "detail-list",
            columns: _vm.columns,
            data: _vm.filterTableData(),
            rowClassName: _vm.rowClassName
          },
          on: {
            "expand-change": _vm.expandChange,
            "row-click": _vm.detailRowClick,
            pageChange: _vm.detailFormPageChange,
            sizeChange: _vm.detailFormSizeChange
          },
          scopedSlots: _vm._u([
            {
              key: "expand",
              fn: function(props) {
                return [
                  _c(
                    "div",
                    { staticStyle: { padding: "0px" } },
                    [
                      _c("YTable", {
                        attrs: {
                          hasIndex: true,
                          pagination: props.row.page,
                          "route-name": _vm.$route.name + "-package",
                          columns: _vm.packageColumns,
                          data: props.row.expands
                        }
                      }),
                      _c(
                        "div",
                        { staticStyle: { margin: "10px 0" } },
                        [
                          _c("el-pagination", {
                            attrs: {
                              background: "",
                              layout: "total, sizes, prev, pager, next, jumper",
                              total: props.row.page.total,
                              "current-page": props.row.page.page,
                              "page-size": props.row.page.pageSize,
                              "page-sizes": [5, 10, 15, 20, 30, 50, 100]
                            },
                            on: {
                              "size-change": function(val) {
                                return _vm.expandRowSizeChange(val, props.row)
                              },
                              "current-change": function(val) {
                                return _vm.expandRowPageChange(val, props.row)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "index",
              fn: function(ref) {
                var row = ref.row
                var index = ref.index
                return [
                  row.expireDays <= 90 && row.expireDays > 0
                    ? _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content: "该产品还有" + row.expireDays + "天过期",
                            placement: "top"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "row",
                                "align-items": "center",
                                "justify-content": "center",
                                position: "relative"
                              }
                            },
                            [
                              _c("d2-icon-svg", {
                                staticStyle: {
                                  width: "16px",
                                  height: "14px",
                                  position: "absolute",
                                  left: "0"
                                },
                                attrs: { name: "warning-yellow" }
                              }),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.indexMethod(index, _vm.detailForm))
                                )
                              ])
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  row.expireDays <= 0
                    ? _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content:
                              "该产品已过期" + Math.abs(row.expireDays) + "天",
                            placement: "top"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "flex-direction": "row",
                                "align-items": "center",
                                "justify-content": "center",
                                position: "relative"
                              }
                            },
                            [
                              _c("d2-icon-svg", {
                                staticStyle: {
                                  width: "16px",
                                  height: "14px",
                                  position: "absolute",
                                  left: "0"
                                },
                                attrs: { name: "warning-red" }
                              }),
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.indexMethod(index, _vm.detailForm))
                                )
                              ])
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  row.expireDays > 90
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.indexMethod(index, _vm.detailForm)))
                      ])
                    : _vm._e()
                ]
              }
            },
            {
              key: "deliveryQuantity",
              fn: function(ref) {
                var row = ref.row
                return [
                  _vm.canEditQuantity(row) === true
                    ? _c("el-input-number", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          size: "mini",
                          min: 0,
                          precision: 0,
                          controls: false,
                          disabled: row.consumTypeId > 0
                        },
                        on: {
                          change: function($event) {
                            return _vm.detailSaveOne(row)
                          }
                        },
                        model: {
                          value: row.deliveryQuantity,
                          callback: function($$v) {
                            _vm.$set(row, "deliveryQuantity", _vm._n($$v))
                          },
                          expression: "row.deliveryQuantity"
                        }
                      })
                    : _c("span", [_vm._v(_vm._s(row.deliveryQuantity))])
                ]
              }
            },
            {
              key: "productName",
              fn: function(ref) {
                var row = ref.row
                return [
                  row.consumTypeId > 0
                    ? _c(
                        "el-tag",
                        {
                          staticStyle: { cursor: "pointer" },
                          attrs: { size: "mini", type: "danger" },
                          on: {
                            click: function($event) {
                              return _vm.viewPack(row, "detail")
                            }
                          }
                        },
                        [_vm._v("耗材包-" + _vm._s(row.productName))]
                      )
                    : _c("span", [_vm._v(_vm._s(row.productName))])
                ]
              }
            },
            {
              key: "isConsign",
              fn: function(ref) {
                var row = ref.row
                return _c(
                  "span",
                  {},
                  [
                    row.isConsign === 1
                      ? _c(
                          "el-tag",
                          {
                            attrs: {
                              type: "success",
                              effect: "dark",
                              size: "mini"
                            }
                          },
                          [_vm._v("是")]
                        )
                      : _c(
                          "el-tag",
                          {
                            attrs: {
                              type: "info",
                              effect: "dark",
                              size: "mini"
                            }
                          },
                          [_vm._v("否")]
                        )
                  ],
                  1
                )
              }
            },
            {
              key: "specificationModel",
              fn: function(ref) {
                var row = ref.row
                return [
                  row.consumTypeId > 0
                    ? _c(
                        "span",
                        [
                          _c(
                            "el-tag",
                            {
                              staticStyle: {
                                "margin-left": "5px",
                                cursor: "pointer"
                              },
                              attrs: { type: "primary", size: "mini" },
                              on: {
                                click: function($event) {
                                  return _vm.viewPack(row, "detail")
                                }
                              }
                            },
                            [_vm._v("明细汇总")]
                          )
                        ],
                        1
                      )
                    : _c("span", [_vm._v(_vm._s(row.specificationModel))])
                ]
              }
            },
            {
              key: "quantityUsed",
              fn: function(ref) {
                var row = ref.row
                return [
                  _vm.canEditUsed(row) === true
                    ? _c(
                        "div",
                        [
                          row.consumTypeId > 0
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                    "justify-content": "space-between",
                                    border: "1px solid #409eff",
                                    "border-radius": "3px",
                                    cursor: "pointer"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.viewPack(row, "box")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        flex: "1",
                                        display: "flex",
                                        "align-items": "center",
                                        "justify-content": "center",
                                        "border-right": "1px solid #409eff",
                                        background: "#fff"
                                      }
                                    },
                                    [_vm._v(_vm._s(row.consumedQuantitySum))]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        padding: "0 5px",
                                        background: "#409eff",
                                        color: "#fff",
                                        "font-size": "12px",
                                        display: "flex",
                                        "align-items": "center"
                                      }
                                    },
                                    [_vm._v("填写消耗")]
                                  )
                                ]
                              )
                            : _c("el-input-number", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  label: "使用数量",
                                  size: "mini",
                                  precision: 0,
                                  controls: false,
                                  min: 0
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.detailSaveOne(row)
                                  }
                                },
                                model: {
                                  value: row.quantityUsed,
                                  callback: function($$v) {
                                    _vm.$set(row, "quantityUsed", _vm._n($$v))
                                  },
                                  expression: "row.quantityUsed"
                                }
                              })
                        ],
                        1
                      )
                    : _c("span", [
                        row.consumTypeId
                          ? _c("span", [
                              _vm._v(_vm._s(row.consumedQuantitySum))
                            ])
                          : _c("span", [_vm._v(_vm._s(row.quantityUsed))])
                      ])
                ]
              }
            },
            {
              key: "remarks",
              fn: function(ref) {
                var row = ref.row
                return [
                  _vm.canEditQuantity(row) === true
                    ? _c("el-input", {
                        attrs: { label: "备注", size: "mini" },
                        on: {
                          change: function($event) {
                            return _vm.detailSaveOne(row)
                          }
                        },
                        model: {
                          value: row.remarks,
                          callback: function($$v) {
                            _vm.$set(row, "remarks", $$v)
                          },
                          expression: "row.remarks"
                        }
                      })
                    : _c("span", [_vm._v(" " + _vm._s(row.remarks) + " ")])
                ]
              }
            }
          ])
        })
      ],
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "flex-direction": "column",
              "justify-content": "space-between",
              "align-items": "center"
            }
          },
          [
            _c(
              "el-breadcrumb",
              {
                staticStyle: {
                  "font-size": "12px",
                  "margin-bottom": "10px",
                  width: "100%"
                },
                attrs: { separator: " " }
              },
              [
                _c("el-breadcrumb-item", [
                  _vm._v("制单人：" + _vm._s(_vm.form.issuePersonnel))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("制单时间：" + _vm._s(_vm.form.CreatedAt))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("出库人：" + _vm._s(_vm.form.nameWarehousePersonnel))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("出库时间：" + _vm._s(_vm.form.deliveryTime))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("回库人：" + _vm._s(_vm.form.returningPersonnel))
                ]),
                _c("el-breadcrumb-item", [
                  _vm._v("回库时间：" + _vm._s(_vm.form.returnDate))
                ])
              ],
              1
            ),
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.formLoading,
                    expression: "formLoading"
                  }
                ],
                staticStyle: { margin: "0px", width: "100%" },
                attrs: { size: "small" },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { staticStyle: { margin: "0" }, attrs: { align: "left" } },
                  [
                    _vm.form.status === 1 ||
                    (_vm.form.status == 2 && _vm.form.deliveryStatus === 8) ||
                    (_vm.form.status === 3 && _vm.form.deliveryStatus === 8)
                      ? _c(
                          "el-button",
                          {
                            key: "remove-button",
                            attrs: {
                              type: "danger",
                              disabled: _vm.canDelete() === false
                            },
                            on: { click: _vm.detailRowRemove }
                          },
                          [_vm._v("删除行 ")]
                        )
                      : _vm._e(),
                    _vm.form.id && _vm.form.status == 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "submit-button",
                            attrs: { type: "primary" },
                            on: { click: _vm.submitCheck }
                          },
                          [_vm._v(" 提交")]
                        )
                      : _vm._e(),
                    _vm.form.status === 2 && _vm.form.deliveryStatus === 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "un-submit-button",
                            attrs: { type: "danger", disabled: !_vm.form.id },
                            on: { click: _vm.unFormSubmit }
                          },
                          [
                            _c("i", { staticClass: "fa el-icon-refresh-left" }),
                            _vm._v(" 撤销提交")
                          ]
                        )
                      : _vm._e(),
                    _vm.form.status == 2 && _vm.isCancel() === false
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "out-warehouse-button",
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.checkLoading(_vm.orderOutWarehouse)
                              }
                            }
                          },
                          [_vm._v("发货出库")]
                        )
                      : _vm._e(),
                    _vm.form.status === 3 && _vm.form.deliveryStatus === 1
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "cancel-out-button",
                            attrs: { type: "danger", disabled: !_vm.form.id },
                            on: { click: _vm.orderUnOutWarehouse }
                          },
                          [
                            _c("i", { staticClass: "fa el-icon-refresh-left" }),
                            _vm._v(" 撤销出库")
                          ]
                        )
                      : _vm._e(),
                    _vm.form.status === 3 &&
                    _vm.form.deliveryStatus === 1 &&
                    _vm.form.hospitalStock === 0
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "assign-delivery-button",
                            attrs: { type: "primary" },
                            on: { click: _vm.orderOutWarehouseAssign }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-ambulance",
                              attrs: { "aria-hidden": "true" }
                            }),
                            _vm._v(" 安排出库配送")
                          ]
                        )
                      : _vm._e(),
                    _vm.form.status === 3 && _vm.form.deliveryStatus === 2
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "uncancel-button",
                            attrs: { type: "danger" },
                            on: { click: _vm.cancelOutWarehouse }
                          },
                          [
                            _c("i", { staticClass: "fa el-icon-refresh-left" }),
                            _vm._v(" 撤销出库配送")
                          ]
                        )
                      : _vm._e(),
                    _vm.form.status === 3 && _vm.form.deliveryStatus === 15
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "delivery-button",
                            attrs: { type: "primary" },
                            on: { click: _vm.orderInWarehouseAssign }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-ambulance",
                              attrs: { "aria-hidden": "true" }
                            }),
                            _vm._v(" 安排回库配送")
                          ]
                        )
                      : _vm._e(),
                    _vm.form.status === 3 && _vm.form.deliveryStatus === 5
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "cancel-return-button",
                            attrs: { type: "danger" },
                            on: { click: _vm.cancelInWarehouse }
                          },
                          [
                            _c("i", { staticClass: "fa el-icon-refresh-left" }),
                            _vm._v(" 撤销回库配送")
                          ]
                        )
                      : _vm._e(),
                    (_vm.form.status === 3 && _vm.form.deliveryStatus === 8) ||
                    (_vm.form.status === 3 &&
                      _vm.form.hospitalStock === 1 &&
                      _vm.form.deliveryStatus === 1) ||
                    (_vm.form.status === 2 && _vm.isCancel() === true)
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "confirm-button",
                            attrs: { type: "primary", icon: "el-icon-check" },
                            on: { click: _vm.orderInWarehouseCheck }
                          },
                          [_vm._v("回库确认")]
                        )
                      : _vm._e(),
                    _vm.form.status === 6 && _vm.form.salesStatus <= 2
                      ? _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "prevent-re-click",
                                rawName: "v-prevent-re-click"
                              }
                            ],
                            key: "return-button",
                            attrs: { type: "danger" },
                            on: { click: _vm.unOrderInWarehouse }
                          },
                          [
                            _c("i", { staticClass: "fa el-icon-refresh-left" }),
                            _vm._v(" 撤销回库")
                          ]
                        )
                      : _vm._e(),
                    _vm.form.hospitalName === "平谷区医院"
                      ? _c(
                          "el-button",
                          {
                            key: "custom-button",
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.toCustomPrint()
                              }
                            }
                          },
                          [_vm._v("自定义打印")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("DetailListComponents", {
        ref: "DetailListComponents",
        on: { confirm: _vm.detailConfirm }
      }),
      _c("ExpressFormComponents", {
        ref: "ExpressFormComponents",
        on: { reload: _vm.orderExpressResult }
      }),
      _c("PrintComponents", { ref: "PrintComponents" }),
      _c("CustomPrintComponent", { ref: "CustomPrintComponentRef" }),
      _c("StockSearchComponents", {
        ref: "StockSearchComponents",
        on: { confirm: _vm.stockAddToList }
      }),
      _c("ConsumSearchComponents", {
        ref: "ConsumSearchComponents",
        on: { confirm: _vm.consumAddToList }
      }),
      _c("ConsumSetSearchComponents", {
        ref: "ConsumSetSearchComponents",
        on: { confirm: _vm.consumSetAddToList }
      }),
      _c("ViewPackComponents", {
        ref: "ViewPackComponents",
        on: { confirm: _vm.loadDetail }
      }),
      _c("ScanInputComponents", {
        ref: "ScanInputComponents",
        on: { confirm: _vm.stockAddToList }
      }),
      _c("EditRemarkComponents", {
        ref: "EditRemarkComponents",
        on: { confirm: _vm.reload }
      }),
      _c("ExpireDialogComponents", {
        ref: "ExpireDialogComponents",
        on: { confirm: _vm.expireConfirm }
      }),
      _c("RecommandProductComponent", {
        ref: "RecommandProductComponentRef",
        on: { confirm: _vm.consumSetAddToList }
      }),
      _c("ScanUploadComponent", {
        ref: "ScanUploadComponentRef",
        on: {
          reload: function($event) {
            return _vm.syncPhoneData()
          }
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.uploadPreviewVisible,
            "append-to-body": true,
            "modal-append-to-body": false,
            "destroy-on-close": true
          },
          on: {
            "update:visible": function($event) {
              _vm.uploadPreviewVisible = $event
            }
          }
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.uploadPreviewImageUrl, alt: "" }
          })
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }