var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("财务管理")]),
              _c("el-breadcrumb-item", [_vm._v("采购管理")]),
              _c("el-breadcrumb-item", [_vm._v("采购核销")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-refresh-right"
                },
                on: { click: _vm.refresh }
              })
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-card",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.searchFormShow,
                expression: "searchFormShow"
              }
            ],
            staticClass: "form-card",
            attrs: { shadow: "never" }
          },
          [
            _c(
              "el-form",
              {
                attrs: {
                  inline: true,
                  model: _vm.searchForm,
                  size: "small",
                  "label-suffix": ":"
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchFormSubmit.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "采购单号" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "采购单号", clearable: "" },
                      model: {
                        value: _vm.searchForm.purchaseOrderNumbers,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "purchaseOrderNumbers", $$v)
                        },
                        expression: "searchForm.purchaseOrderNumbers"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "供应商" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { clearable: "", filterable: "" },
                        model: {
                          value: _vm.searchForm.manufacturerName,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "manufacturerName", $$v)
                          },
                          expression: "searchForm.manufacturerName"
                        }
                      },
                      _vm._l(_vm.manufacturerList, function(item, index) {
                        return _c("el-option", {
                          key: "manufacturerName-" + index,
                          attrs: { label: item.name, value: item.name }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "采购人" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "采购人", clearable: "" },
                      model: {
                        value: _vm.searchForm.buyer,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "buyer", $$v)
                        },
                        expression: "searchForm.buyer"
                      }
                    })
                  ],
                  1
                ),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "采购日期" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            "unlink-panels": true,
                            "range-separator": "至",
                            "value-format": "yyyy-MM-dd",
                            format: "yyyy-MM-dd",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期"
                          },
                          model: {
                            value: _vm.searchForm.dateRange,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "dateRange", $$v)
                            },
                            expression: "searchForm.dateRange"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "入库情况" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "", filterable: "" },
                            model: {
                              value: _vm.searchForm.storageStatus,
                              callback: function($$v) {
                                _vm.$set(_vm.searchForm, "storageStatus", $$v)
                              },
                              expression: "searchForm.storageStatus"
                            }
                          },
                          _vm._l(_vm.storageStatusList, function(item, index) {
                            return _c("el-option", {
                              key: "storageStatus-" + index,
                              attrs: { label: item.name, value: item.ID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "付款人" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "付款人", clearable: "" },
                          model: {
                            value: _vm.searchForm.payName,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "payName", $$v)
                            },
                            expression: "searchForm.payName"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "付款日期" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            "unlink-panels": true,
                            "range-separator": "至",
                            "value-format": "yyyy-MM-dd",
                            format: "yyyy-MM-dd",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期"
                          },
                          model: {
                            value: _vm.searchForm.dateRange2,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "dateRange2", $$v)
                            },
                            expression: "searchForm.dateRange2"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "申请单号" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "申请单号", clearable: "" },
                          model: {
                            value: _vm.searchForm.appOrderNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "appOrderNumber", $$v)
                            },
                            expression: "searchForm.appOrderNumber"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "开票情况" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "" },
                            model: {
                              value: _vm.searchForm.invoiceSituation,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.searchForm,
                                  "invoiceSituation",
                                  $$v
                                )
                              },
                              expression: "searchForm.invoiceSituation"
                            }
                          },
                          _vm._l(_vm.invoiceSituationList, function(
                            item,
                            index
                          ) {
                            return _c("el-option", {
                              key: "invoiceSituation" + index,
                              attrs: { label: item.name, value: item.ID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "付款公司" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "" },
                            model: {
                              value: _vm.searchForm.paymentCompany,
                              callback: function($$v) {
                                _vm.$set(_vm.searchForm, "paymentCompany", $$v)
                              },
                              expression: "searchForm.paymentCompany"
                            }
                          },
                          _vm._l(_vm.subsidiaryList, function(item, index) {
                            return _c("el-option", {
                              key: "paymentCompany" + index,
                              attrs: {
                                label: item.companyName,
                                value: item.companyName
                              }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-right": "10px" },
                        attrs: { type: "primary", icon: "el-icon-search" },
                        on: { click: _vm.searchFormSubmit }
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-right": "10px" },
                        attrs: { type: "success" },
                        on: { click: _vm.batchCheck }
                      },
                      [_vm._v("批量核销")]
                    ),
                    _c(
                      "el-link",
                      {
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function($event) {
                            _vm.searchFormExpand = !_vm.searchFormExpand
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(_vm.searchFormExpand ? "合并" : "展开")),
                        _c("i", {
                          class:
                            "el-icon-arrow-" +
                            (_vm.searchFormExpand ? "up" : "down") +
                            " el-icon--right"
                        })
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.orderLoading,
              expression: "orderLoading"
            }
          ],
          ref: "dragTableRef",
          attrs: {
            hasSelection: true,
            hasIndex: true,
            pagination: _vm.searchForm,
            "route-name": _vm.$route.name,
            columns: _vm.columns,
            data: _vm.orderList,
            selectable: _vm.selectable
          },
          on: { "row-dblclick": _vm.viewOrder },
          scopedSlots: _vm._u([
            {
              key: "orderNumber",
              fn: function(ref) {
                var row = ref.row
                return [
                  row.orderNumber.length > 1
                    ? _c(
                        "el-popover",
                        {
                          attrs: {
                            placement: "right",
                            width: "auto",
                            trigger: "click"
                          }
                        },
                        [
                          _c(
                            "div",
                            _vm._l(row.orderNumber, function(item, idx) {
                              return _c(
                                "div",
                                { key: "orderNumber-" + idx },
                                [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.viewPurchaseOrder(item)
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(item))]
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          _c(
                            "el-link",
                            {
                              attrs: { slot: "reference", type: "primary" },
                              slot: "reference"
                            },
                            [
                              _vm._v(" " + _vm._s(row.orderNumber[0]) + " "),
                              row.orderNumber.length > 1
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "#f00" } },
                                    [
                                      _vm._v(
                                        "+" + _vm._s(row.orderNumber.length - 1)
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      )
                    : row.orderNumber.length === 1
                    ? _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.viewPurchaseOrder(row.orderNumber[0])
                            }
                          }
                        },
                        [_vm._v(_vm._s(row.orderNumber[0]))]
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "appOrderNumber",
              fn: function(ref) {
                var row = ref.row
                return [
                  row.appOrderNumber.length > 1
                    ? _c(
                        "el-popover",
                        { attrs: { placement: "right", trigger: "click" } },
                        [
                          _c(
                            "div",
                            { staticClass: "el-list" },
                            _vm._l(row.appOrderNumber, function(item, idx) {
                              return _c(
                                "div",
                                { key: "appOrderNumber-" + idx },
                                [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.viewApply(item)
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(item))]
                                  )
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          _c(
                            "el-link",
                            {
                              attrs: { slot: "reference", type: "primary" },
                              slot: "reference"
                            },
                            [
                              _vm._v(" " + _vm._s(row.appOrderNumber[0]) + " "),
                              row.appOrderNumber.length > 1
                                ? _c(
                                    "span",
                                    { staticStyle: { color: "#f00" } },
                                    [
                                      _vm._v(
                                        "+" + _vm._s(row.appOrderNumber.length)
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      )
                    : row.appOrderNumber.length === 1
                    ? _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.viewApply(row.appOrderNumber[0])
                            }
                          }
                        },
                        [_vm._v(_vm._s(row.appOrderNumber[0]))]
                      )
                    : _vm._e()
                ]
              }
            },
            {
              key: "payName",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "el-popover",
                    { attrs: { placement: "left", trigger: "click" } },
                    [
                      _c(
                        "div",
                        { staticClass: "el-list" },
                        _vm._l(row.payName, function(item, idx) {
                          return _c(
                            "div",
                            { key: "payName-" + idx },
                            [
                              _c("el-link", { attrs: { type: "primary" } }, [
                                _vm._v(_vm._s(item))
                              ])
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _c(
                        "el-link",
                        {
                          attrs: { slot: "reference", type: "primary" },
                          slot: "reference"
                        },
                        [
                          _vm._v(" " + _vm._s(row.payName[0]) + " "),
                          row.payName.length > 1
                            ? _c("span", { staticStyle: { color: "#f00" } }, [
                                _vm._v("+" + _vm._s(row.payName.length))
                              ])
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "payDate",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "el-popover",
                    { attrs: { placement: "left", trigger: "click" } },
                    [
                      _c(
                        "div",
                        { staticClass: "el-list" },
                        _vm._l(row.payDate, function(item, idx) {
                          return _c(
                            "div",
                            { key: "payDate-" + idx },
                            [
                              _c("el-link", { attrs: { type: "primary" } }, [
                                _vm._v(_vm._s(_vm.dateFormatter(item)))
                              ])
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _c(
                        "el-link",
                        {
                          attrs: { slot: "reference", type: "primary" },
                          slot: "reference"
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.dateFormatter(row.payDate[0])) +
                              " "
                          ),
                          row.payDate.length > 1
                            ? _c("span", { staticStyle: { color: "#f00" } }, [
                                _vm._v("+" + _vm._s(row.payDate.length))
                              ])
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "operations",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "div",
                    { staticClass: "control-column" },
                    [
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.viewOrder(row)
                            }
                          }
                        },
                        [_vm._v("详情")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        }),
        _c(
          "div",
          { staticStyle: { margin: "10px 0" } },
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                layout: "total, sizes, prev, pager, next, jumper",
                align: "center",
                total: _vm.searchForm.total,
                "page-sizes": [5, 10, 20, 50, 100],
                "current-page": _vm.searchForm.page,
                "page-size": _vm.searchForm.pageSize
              },
              on: {
                "current-change": _vm.orderPageChange,
                "size-change": _vm.orderSizeChange
              }
            })
          ],
          1
        ),
        _c("ViewApplyComponent", { ref: "ViewApplyComponentRef" })
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }