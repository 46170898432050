var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("财务管理")]),
              _c("el-breadcrumb-item", [_vm._v("销售管理")]),
              _c("el-breadcrumb-item", [_vm._v("销售退货单审核")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-refresh-right"
                },
                on: { click: _vm.refresh }
              })
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-card",
          { staticClass: "form-card", attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                attrs: {
                  inline: true,
                  model: _vm.searchForm,
                  size: "small",
                  "label-suffix": ":"
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchFormSubmit.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "仓库" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择", clearable: "" },
                        model: {
                          value: _vm.searchForm.warehouseId,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "warehouseId", $$v)
                          },
                          expression: "searchForm.warehouseId"
                        }
                      },
                      _vm._l(_vm.warehouseList, function(item) {
                        return _c("el-option", {
                          key: item.ID,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "经销商" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择", clearable: "" },
                        model: {
                          value: _vm.searchForm.manufacturerId,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "manufacturerId", $$v)
                          },
                          expression: "searchForm.manufacturerId"
                        }
                      },
                      _vm._l(_vm.manufacturerList, function(item) {
                        return _c("el-option", {
                          key: item.ID,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "制单状态" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.searchForm.status,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "status", $$v)
                          },
                          expression: "searchForm.status"
                        }
                      },
                      _vm._l(_vm.statusList, function(item) {
                        return _c("el-option", {
                          key: item.ID,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: { placeholder: "制单日期" },
                            model: {
                              value: _vm.searchForm.timeType,
                              callback: function($$v) {
                                _vm.$set(_vm.searchForm, "timeType", $$v)
                              },
                              expression: "searchForm.timeType"
                            }
                          },
                          _vm._l(_vm.timeTypeList, function(item) {
                            return _c("el-option", {
                              key: item.ID,
                              attrs: { label: item.name, value: item.ID }
                            })
                          }),
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "" } },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            "unlink-panels": true,
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchForm.dateRange,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "dateRange", $$v)
                            },
                            expression: "searchForm.dateRange"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.searchFormExpand
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "退货单号" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入退货单号",
                            clearable: ""
                          },
                          model: {
                            value: _vm.searchForm.deliveryOrderNo,
                            callback: function($$v) {
                              _vm.$set(_vm.searchForm, "deliveryOrderNo", $$v)
                            },
                            expression: "searchForm.deliveryOrderNo"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.searchFormSubmit }
                      },
                      [_vm._v("查询")]
                    ),
                    _c("el-button", { on: { click: _vm.dataExport } }, [
                      _vm._v("导出")
                    ]),
                    _c(
                      "el-link",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "primary", underline: false },
                        on: {
                          click: function($event) {
                            _vm.searchFormExpand = !_vm.searchFormExpand
                          }
                        }
                      },
                      [
                        _vm._v(_vm._s(_vm.searchFormExpand ? "合并" : "展开")),
                        _c("i", {
                          class:
                            "el-icon-arrow-" +
                            (_vm.searchFormExpand ? "up" : "down") +
                            " el-icon--right"
                        })
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.orderLoading,
              expression: "orderLoading"
            }
          ],
          ref: "dragTableRef",
          attrs: {
            hasIndex: true,
            pagination: _vm.searchForm,
            "route-name": _vm.$route.name,
            columns: _vm.columns,
            data: _vm.orderList
          },
          on: {
            "row-click": _vm.orderRowClick,
            "row-dblclick": _vm.orderRowDbClick
          },
          scopedSlots: _vm._u([
            {
              key: "orderNumber",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "el-popover",
                    {
                      ref: "barCodePopover" + row.ID,
                      attrs: { placement: "right", trigger: "click" }
                    },
                    [
                      _c("div", [
                        _c("img", { attrs: { id: "barCodeImage" + row.ID } })
                      ]),
                      _c(
                        "el-link",
                        {
                          attrs: {
                            slot: "reference",
                            underline: false,
                            type: "primary"
                          },
                          on: {
                            click: function($event) {
                              return _vm.makeBarCode(row.ID, row.orderNumber)
                            }
                          },
                          slot: "reference"
                        },
                        [_vm._v(_vm._s(row.orderNumber))]
                      )
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "operations",
              fn: function(ref) {
                var row = ref.row
                return [
                  _c(
                    "div",
                    { staticClass: "control-column" },
                    [
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.orderEdit(row)
                            }
                          }
                        },
                        [_vm._v("详情")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        }),
        _c(
          "div",
          { staticStyle: { margin: "10px 0" } },
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                layout: "total, sizes, prev, pager, next, jumper",
                align: "center",
                total: _vm.searchForm.total,
                "page-sizes": [5, 10, 20, 50, 100],
                "current-page": _vm.searchForm.page,
                "page-size": _vm.searchForm.pageSize
              },
              on: {
                "current-change": _vm.orderPageChange,
                "size-change": _vm.orderSizeChange
              }
            })
          ],
          1
        ),
        _c("el-divider", { attrs: { "content-position": "left" } }, [
          _vm._v("耗材明细")
        ]),
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.detailLoading,
                expression: "detailLoading"
              }
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.detailList,
              size: "small",
              "highlight-current-row": "",
              stripeDELETE: "",
              border: "",
              "header-cell-style": {
                background: "#f5f7fa",
                borderColor: "#ebeef5",
                color: "#333"
              },
              "row-class-name": _vm.rowClassName
            }
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "index",
                index: function(index) {
                  return _vm.indexMethod(index, _vm.detailForm)
                },
                label: "序号",
                width: "100",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "productNumber",
                label: "产品编号",
                width: "100",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "main.productName",
                label: "物资名称",
                width: "150",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "specs.genericName",
                label: "通用名称",
                width: "150",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "inventory.manufacturerName",
                label: "供应商",
                width: "150",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "inventory.trackingCode",
                label: "序列号",
                width: "150",
                align: "center",
                "show-overflow-tooltip": ""
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "specs.specificationModel",
                width: "100",
                label: "规格型号",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "inventory.batch_number",
                label: "生产批号",
                width: "100",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "returnQuantity",
                width: "100",
                label: "退货数量",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "main.registrationNumber",
                width: "150",
                label: "注册证号",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "inventory.normalQuantity",
                width: "150",
                label: "在库数量",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "inventory.manufacturer_date",
                width: "150",
                label: "生产日期",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "inventory.validity_date",
                width: "150",
                label: "有效期",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "main.brandName",
                width: "150",
                label: "品牌",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "specs.unit",
                width: "150",
                label: "单位",
                align: "center"
              }
            }),
            _c("el-table-column", {
              attrs: {
                prop: "",
                width: "150",
                label: "包装规格",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(scope.row.specs.packNum) +
                          _vm._s(scope.row.specs.unit) +
                          "/" +
                          _vm._s(scope.row.specs.packUnit) +
                          " "
                      )
                    ]
                  }
                }
              ])
            }),
            _c("el-table-column", {
              attrs: {
                prop: "main.manufacturer",
                width: "150",
                label: "生产厂家",
                align: "center",
                "show-overflow-tooltip": ""
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { margin: "10px 0" } },
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                layout: "total, sizes, prev, pager, next, jumper",
                align: "center",
                total: _vm.detailForm.total,
                "page-sizes": [5, 10, 20, 50, 100],
                "current-page": _vm.detailForm.page,
                "page-size": _vm.detailForm.pageSize
              },
              on: {
                "current-change": _vm.detailPageChange,
                "size-change": _vm.detailSizeChange
              }
            })
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }