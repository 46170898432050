var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("统计报表")]),
              _c("el-breadcrumb-item", [_vm._v("财务报表")]),
              _c("el-breadcrumb-item", [_vm._v("库存总值报表")])
            ],
            1
          ),
          _c(
            "div",
            [
              _c("el-button", {
                attrs: {
                  size: "mini",
                  type: "primary",
                  icon: "el-icon-refresh-right"
                },
                on: { click: _vm.refresh }
              })
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-card",
          { staticClass: "form-card", attrs: { shadow: "never" } },
          [
            _c(
              "el-form",
              {
                attrs: {
                  inline: true,
                  model: _vm.searchForm,
                  size: "small",
                  "label-suffix": ":"
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  },
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.searchSubmit.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "仓库" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          clearable: "",
                          multiple: "",
                          "collapse-tags": ""
                        },
                        model: {
                          value: _vm.searchForm.warehouseIds,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "warehouseIds", $$v)
                          },
                          expression: "searchForm.warehouseIds"
                        }
                      },
                      _vm._l(_vm.warehouseList, function(item) {
                        return _c("el-option", {
                          key: item.ID,
                          attrs: { label: item.name, value: item.ID }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "供应商" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择", clearable: "" },
                        model: {
                          value: _vm.searchForm.supplier,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "supplier", $$v)
                          },
                          expression: "searchForm.supplier"
                        }
                      },
                      _vm._l(_vm.supplierList, function(item) {
                        return _c("el-option", {
                          key: item.ID,
                          attrs: { label: item.name, value: item.name }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "物资名称" } },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入物资名称", clearable: "" },
                      model: {
                        value: _vm.searchForm.productName,
                        callback: function($$v) {
                          _vm.$set(_vm.searchForm, "productName", $$v)
                        },
                        expression: "searchForm.productName"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-checkbox",
                      {
                        attrs: { "true-label": 1, "false-label": 0 },
                        model: {
                          value: _vm.searchForm.showEmpty,
                          callback: function($$v) {
                            _vm.$set(_vm.searchForm, "showEmpty", $$v)
                          },
                          expression: "searchForm.showEmpty"
                        }
                      },
                      [_vm._v("不显示0库存物资")]
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { type: "primary" },
                        on: { click: _vm.searchSubmit }
                      },
                      [_vm._v("查询")]
                    ),
                    _c("el-button", { on: { click: _vm.dataExport } }, [
                      _vm._v("导出")
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "el-card",
            staticStyle: { background: "#f9f9f9", margin: "10px 0px" }
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  float: "right",
                  padding: "3px 0",
                  "flex-direction": "row",
                  "font-size": "14px"
                }
              },
              [
                _c("div", { staticStyle: { "margin-right": "20px" } }, [
                  _vm._v("库存总值（含税）合计："),
                  _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.otherData.stockTotalPriceTax))
                  ]),
                  _vm._v("元")
                ]),
                _c("div", { staticStyle: { "margin-right": "20px" } }, [
                  _vm._v("库存总值（不含税）合计："),
                  _c("span", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.otherData.stockTotalPrice))
                  ]),
                  _vm._v("元")
                ])
              ]
            )
          ]
        ),
        _c("YTable", {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          key: "dragTable" + _vm.timestamp,
          ref: "dragTableRef",
          attrs: {
            hasIndex: true,
            pagination: _vm.searchForm,
            "route-name": _vm.$route.name,
            columns: _vm.tableHeadDetail,
            data: _vm.tableData
          }
        })
      ],
      _c(
        "template",
        { slot: "footer" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              align: "center",
              total: _vm.searchForm.total,
              "current-page": _vm.searchForm.page,
              "page-size": _vm.searchForm.pageSize,
              "page-sizes": [5, 10, 20, 50, 100]
            },
            on: {
              "size-change": _vm.pageSizeChange,
              "current-change": _vm.pageChange
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }