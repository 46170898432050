var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page" },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "el-breadcrumb",
            { attrs: { separator: "/" } },
            [
              _c("el-breadcrumb-item", { attrs: { to: { path: "/" } } }, [
                _vm._v("首页")
              ]),
              _c("el-breadcrumb-item", [_vm._v("基础数据")]),
              _c("el-breadcrumb-item", [_vm._v("产品信息管理")]),
              _c("el-breadcrumb-item", [_vm._v("产品线及分类")])
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "el-tabs",
          { attrs: { type: "" } },
          [
            _c(
              "el-tab-pane",
              [
                _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                  _vm._v("产品线及分类")
                ]),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%", "margin-bottom": "20px" },
                    attrs: {
                      data: _vm.tableData,
                      "row-key": function(row) {
                        return _vm.getRowKey(row)
                      },
                      border: "",
                      "tree-props": {
                        children: "children",
                        hasChildren: "hasChildren"
                      },
                      "header-cell-style": {
                        background: "#f5f7fa",
                        borderColor: "#ebeef5",
                        color: "#333"
                      }
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "name",
                        width: "400",
                        label: "厂商/生产线/分类"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "div",
                                { staticStyle: { display: "inline-block" } },
                                [
                                  scope.row.type == "MANUFACTURER"
                                    ? _c("div", [
                                        _vm._v(
                                          " " + _vm._s(scope.row.name) + " "
                                        )
                                      ])
                                    : _vm._e(),
                                  scope.row.type == "PRODUCT_LINE"
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-folder-opened pre-icon"
                                      })
                                    : _vm._e(),
                                  scope.row.type == "PRODUCT_LINE"
                                    ? _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            size: "mini",
                                            placeholder: "请选择产品线"
                                          },
                                          model: {
                                            value: scope.row.lineId,
                                            callback: function($$v) {
                                              _vm.$set(scope.row, "lineId", $$v)
                                            },
                                            expression: "scope.row.lineId"
                                          }
                                        },
                                        _vm._l(_vm.productLineListAll, function(
                                          item,
                                          index
                                        ) {
                                          return _c("el-option", {
                                            key: "opt" + item.ID + "_" + index,
                                            attrs: {
                                              label: item.name,
                                              value: item.ID
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    : _vm._e(),
                                  scope.row.type == "CLASS"
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center"
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-document-copy pre-icon"
                                          }),
                                          _c("el-input", {
                                            attrs: { size: "mini" },
                                            on: {
                                              blur: function($event) {
                                                return _vm.classUpdate(
                                                  scope.row
                                                )
                                              }
                                            },
                                            nativeOn: {
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.classUpdate(
                                                  scope.row
                                                )
                                              }
                                            },
                                            model: {
                                              value: scope.row.name,
                                              callback: function($$v) {
                                                _vm.$set(scope.row, "name", $$v)
                                              },
                                              expression: "scope.row.name"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  scope.row.type == "SUB_CLASS"
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center"
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-tickets pre-icon"
                                          }),
                                          _c("el-input", {
                                            attrs: { size: "mini" },
                                            on: {
                                              blur: function($event) {
                                                return _vm.classUpdate(
                                                  scope.row
                                                )
                                              }
                                            },
                                            nativeOn: {
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.classUpdate(
                                                  scope.row
                                                )
                                              }
                                            },
                                            model: {
                                              value: scope.row.name,
                                              callback: function($$v) {
                                                _vm.$set(scope.row, "name", $$v)
                                              },
                                              expression: "scope.row.name"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              scope.row.type === "MANUFACTURER"
                                ? _c(
                                    "div",
                                    { staticClass: "control-column" },
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function($event) {
                                              return _vm.classLineAdd(scope.row)
                                            }
                                          }
                                        },
                                        [_vm._v("添加品牌产品线")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              scope.row.type === "PRODUCT_LINE"
                                ? _c(
                                    "div",
                                    { staticClass: "control-column" },
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function($event) {
                                              return _vm.classAdd(scope.row)
                                            }
                                          }
                                        },
                                        [_vm._v("新增分类")]
                                      ),
                                      _c(
                                        "el-link",
                                        {
                                          attrs: { type: "danger" },
                                          on: {
                                            click: function($event) {
                                              return _vm.classDelete(scope.row)
                                            }
                                          }
                                        },
                                        [_vm._v("删除")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              scope.row.type === "CLASS"
                                ? _c(
                                    "div",
                                    { staticClass: "control-column" },
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: { type: "danger" },
                                          on: {
                                            click: function($event) {
                                              return _vm.classDelete(scope.row)
                                            }
                                          }
                                        },
                                        [_vm._v("删除")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              scope.row.type === "SUB_CLASS"
                                ? _c(
                                    "div",
                                    { staticClass: "control-column" },
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: { type: "danger" },
                                          on: {
                                            click: function($event) {
                                              return _vm.classDelete(scope.row)
                                            }
                                          }
                                        },
                                        [_vm._v("删除")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "el-tab-pane",
              [
                _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                  _vm._v("产品线管理")
                ]),
                _c(
                  "el-form",
                  {
                    attrs: { size: "small", inline: true },
                    nativeOn: {
                      submit: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [
                    _c(
                      "el-form-item",
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { icon: "el-icon-plus", type: "primary" },
                            on: { click: _vm.lineAdd }
                          },
                          [_vm._v("新增产品线")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.productLineLoading,
                        expression: "productLineLoading"
                      }
                    ],
                    staticStyle: { width: "100%", "margin-bottom": "20px" },
                    attrs: {
                      data: _vm.productLineList,
                      border: "",
                      stripeDELETE: "",
                      "header-cell-style": {
                        background: "#f5f7fa",
                        borderColor: "#ebeef5",
                        color: "#333"
                      }
                    }
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "index",
                        index: function(index) {
                          return _vm.indexMethod(index, _vm.pageForm)
                        },
                        label: "序号",
                        width: "100",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "name",
                        label: "产品线名称",
                        align: "center"
                      }
                    }),
                    _c("el-table-column", {
                      attrs: { label: "操作", width: "200" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "control-column" },
                                [
                                  _c(
                                    "el-link",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.lineEdit(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                  _c(
                                    "el-link",
                                    {
                                      attrs: { type: "danger" },
                                      on: {
                                        click: function($event) {
                                          return _vm.lineRemove(scope.row)
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                ),
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "prev, pager, next, total",
                      align: "center",
                      total: _vm.pageForm.total,
                      "current-page": _vm.pageForm.page,
                      "page-size": _vm.pageForm.pageSize
                    },
                    on: { "current-change": _vm.linePageChange }
                  })
                ]
              ],
              2
            )
          ],
          1
        )
      ],
      _c("LineEditorComponent", {
        ref: "LineEditorComponent",
        on: {
          reload: function($event) {
            return _vm.loadLine()
          }
        }
      }),
      _c("ClassLineEditorComponent", {
        ref: "ClassLineEditorComponent",
        on: {
          reload: function($event) {
            return _vm.loadClass()
          }
        }
      }),
      _c("ClassEditorComponent", {
        ref: "ClassEditorComponent",
        on: {
          reload: function($event) {
            return _vm.loadLine()
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }